import React, { ReactNode, HTMLAttributes } from 'react'

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode
  className?: string
}

const Card: React.FC<CardProps> = ({ children, className, ...props }) => (
  <div
    data-testid="card"
    className={` rounded-2xl border border-gray-100 bg-white shadow-md ${className}`}
    {...props}
  >
    {children}
  </div>
)

export default Card
