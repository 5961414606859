// TrTable.tsx
import React from "react";
import { columnInt } from ".";
import { HiMinusCircle } from "react-icons/hi";
import Icon from "../Icon";
import { useTranslation } from "gatsby-plugin-react-i18next";

interface TrTableProps<T extends { id: string | number }> {
  rowData: T;
  columns: columnInt[];
  hasDeleteIcon?: boolean;
  onClickTr?: (data: T) => void;
}

const TrTable = <T extends { id: string | number }>({
  rowData,
  columns,
  hasDeleteIcon = false,
  onClickTr,
}: TrTableProps<T>) => {
  const { t } = useTranslation();
  return (
    <tr
      key={rowData.id}
      id={`item-table-${rowData.id}`}
      data-testid={`item-table-${rowData.id}`}
      className="h-14 border-b border-gray-200 bg-white text-left text-sm"
      onClick={() => onClickTr?.(rowData)}
      role="button"
    >
      {columns.map(({ key }) =>
        key === "id" ? (
          <></>
        ) : (
          <td
            key={key}
            id={rowData.id.toString()}
            className="truncate hover:cursor-pointer px-4 w-auto text-center"
          >
            {typeof rowData[key as keyof T] === "object" &&
            rowData[key as keyof T] !== null
              ? t((rowData[key as keyof T] as any).name)
              : rowData[key as keyof T]}
          </td>
        )
      )}
      {hasDeleteIcon && (
        <td
          id={rowData.id.toString()}
          className="w-full h-14 flex items-center justify-center"
        >
          <Icon>
            <HiMinusCircle className="text-red-500" size={20} />
          </Icon>
        </td>
      )}
    </tr>
  );
};

export default TrTable;
