import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, Modal, Select } from "../../base";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { navigate } from "gatsby";
import { useMutation } from "react-query";

import axios, { AxiosError } from "axios";
import { fetchUser } from "./EducationForm";
import { Gender, useAppContext } from "../../../context/AppProvider";
import { URL_API } from "../../../utils/constants";
import { control } from "leaflet";

interface EducationDto {
  university: string;
  degree: string;
  year: number;
  type: string;
}
export const EDUCATION_TYPES = [
  {
    id: "UNDERGRADUATE",
    key: "UNDERGRADUATE",
    en: "Undergraduate",
    es: "Licenciatura",
  },
  {
    id: "MASTER",
    key: "MASTER",
    en: "Master",
    es: "Maestria",
  },
  {
    id: "DOCTORATE",
    key: "DOCTORATE",
    en: "Doctorate",
    es: "Doctorado",
  },
  {
    id: "POSTGRADUATE",
    key: "POSTGRADUATE",
    en: "Postgraduate",
    es: "Posgrado",
  },
];
const ModalEducation = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const { accessToken, user, setContextValue } = useAppContext();
  const EducationSchema = yup.object().shape({
    university: yup.string().required(t("University is required")),
    degree: yup.string().required(t("Title is required")),
    year: yup
      .number()
      .max(new Date().getFullYear())
      .min(1950)
      .required(t("Year is required")),
    type: yup.string().required(t("Education Type is required")),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<EducationDto>({
    resolver: yupResolver(EducationSchema),
  });

  const mutation = useMutation(
    (data: EducationDto) =>
      axios.post(
        `${URL_API}/user-profile/${user?.userProfile?.id}/education`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ),
    {
      onError: (e: AxiosError) => {
        if (e.response?.status === 401) {
          navigate("/auth/login");
        }
      },
      onSuccess: () => {
        fetchUser(user, accessToken, setContextValue);
        reset();
        closeModal();
      },
    }
  );

  const onSubmit = (data: EducationDto) => {
    mutation.mutate(data);
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="flex max-h-[80vh] flex-col justify-between">
        <h2 className="mb-4 text-lg font-bold">{t("Add new education")}</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto w-full">
          <div className="my-6">
            <Input
              type="text"
              id="university"
              placeholder={t("university") ?? ""}
              {...register("university", { required: true })}
            />

            {errors.university && (
              <span className="text-red-500">{errors.university.message}</span>
            )}
          </div>

          <div className="mb-4">
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder={t("Education Type")}
                  items={EDUCATION_TYPES}
                  value={field.value}
                  onChange={field.onChange as any}
                  disabled={false}
                  multiple={false}
                />
              )}
            />

            {errors.type && (
              <span className="text-red-500">{errors.type.message}</span>
            )}
          </div>
          <div className="my-6">
            <Input
              type="text"
              id="degree"
              placeholder={t("degree") ?? ""}
              {...register("degree", { required: true })}
            />

            {errors.degree && (
              <span className="text-red-500">{errors.degree.message}</span>
            )}
          </div>
          <div className="my-6">
            <Input
              type="number"
              id="year"
              defaultValue={1980}
              placeholder={t("year") ?? ""}
              {...register("year", { required: true })}
            />

            {errors.year && (
              <span className="text-red-500">{errors.year.message}</span>
            )}
          </div>

          <Button
            disabled={mutation.isLoading || isSubmitting}
            type="submit"
            variant="teal"
            className="mt-4 h-12"
          >
            {mutation.isLoading ? t("Saving...") : t("Save")}
          </Button>
        </form>
      </div>
    </Modal>
  );
};
export default ModalEducation;
