import React, { ButtonHTMLAttributes } from "react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "teal" | "secondary" | "outline" | "default" | "light" | "danger";
  children: React.ReactNode | string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  variant,
  children,
  onClick,
  disabled,
  className,
  ...props
}) => {
  const classNameColors = {
    teal: "border border-transparent text-white bg-teal-700 hover:bg-teal-600",
    secondary: "border border-transparent bg-teal-600 hover:bg-teal-700",
    outline: `border border-2 border-teal-900 text-teal-900 bg-transparent hover:bg-gray-200`,
    default: "border border-transparent bg-transparent text-black",
    light: "border border-transparent bg-[#F3F4F6] text-gray-700",
    danger: "border border-transparent bg-red-600 text-white",
  };
  const customOnClick = (e: any) => {
    if (!disabled) {
      if (onClick) {
        onClick(e);
      }
    }
  };

  return (
    <button
      className={`${
        disabled
          ? "bg-gray-100 text-gray-500"
          : classNameColors[variant ?? "teal"]
      } h-full w-full rounded-lg px-2 py-2.5 focus:outline-none ${className}`}
      disabled={disabled}
      onClick={customOnClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
