import React, { useLayoutEffect, useState } from "react";
import { Icon, Table } from "../../base";
import { HiOutlineExclamationCircle, HiPlusCircle } from "react-icons/hi";
import ModalEducation, { EDUCATION_TYPES } from "./ModalEducation";
import { Portal } from "@headlessui/react";
import { Button, Modal } from "flowbite-react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import axios, { AxiosError } from "axios";
import { navigate } from "gatsby";
import { useMutation } from "react-query";
import { useAppContext } from "../../../context/AppProvider";
import { URL_API } from "../../../utils/constants";

const processEducationData = (data: any[], currentLanguage: string) => {
  return data?.map((item) => {
    const { id, university, degree, year, type } = item;
    return {
      id,
      university,
      degree,
      year,
      type:
        EDUCATION_TYPES.find((t) => t.id === type)?.[
          currentLanguage as "es" | "en"
        ] ?? "",
    };
  });
};
export const fetchUser = async (
  user: any,
  accessToken: string,
  setContextValue: any
) => {
  const response = await axios.get(`${URL_API}/users/profile/${user.id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = response.data;
  return setContextValue("user", {
    ...user,
    userProfile: data,
  });
};
export interface UserEducation {
  id: number;
  university: string;
  degree: string;
  year: number;
  type: string;
}
const EducationForm = () => {
  const { t } = useTranslation();
  const { user, accessToken, setContextValue } = useAppContext();
  const [processedEducationData, setProcessedEducationData] = useState([]);
  const { language: currentLanguage } = useI18next();
  const [selectedRow, setSelectedRow] = useState<UserEducation | null>(null);
  const handleRowClick = (row: UserEducation) => {
    setSelectedRow(row);
    setOpenModal("pop-up");
  };
  useLayoutEffect(() => {
    setProcessedEducationData(
      processEducationData(user?.userProfile?.educations ?? [], currentLanguage)
    );
  }, [user?.userProfile?.educations]);

  const mutation = useMutation(
    (data: UserEducation) =>
      axios.delete(
        `${URL_API}/user-profile/${user?.userProfile?.id}/education/${data.id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ),
    {
      onError: (e: AxiosError) => {
        if (e.response?.status === 401) {
          navigate("/auth/login");
        }
      },
      onSuccess: () => {
        fetchUser(user, accessToken, setContextValue);
        setOpenModal(undefined);
      },
    }
  );
  const deleteRow = () => {
    mutation.mutate(selectedRow);
    setProcessedEducationData(
      processedEducationData.splice(
        processedEducationData.findIndex((item) => item.id === selectedRow.id),
        1
      )
    );
  };

  const [openModal, setOpenModal] = useState<string | undefined>();
  const props = { openModal, setOpenModal };
  return (
    <>
      <Modal
        show={props.openModal === "pop-up"}
        size="md"
        popup
        onClose={() => props.setOpenModal(undefined)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-600" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {t("Are you sure you want to delete it?")}
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={deleteRow}
                disabled={mutation.isLoading}
              >
                {mutation.isLoading ? t("Deleting...") : t("Yes, delete")}
              </Button>
              <Button
                color="gray"
                onClick={() => props.setOpenModal(undefined)}
              >
                {t("No, cancel")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <p className="text-teal-500 font-semibold text-start py-4 flex items-center">
        {t("Education")}
        <Icon onClick={() => setOpenModal("create")}>
          <HiPlusCircle className="text-teal-700 ml-2"></HiPlusCircle>
        </Icon>
      </p>
      <Portal>
        <ModalEducation
          isOpen={openModal === "create"}
          closeModal={() => setOpenModal(undefined)}
        />
      </Portal>
      {processedEducationData?.length > 0 && (
        <Table
          id="education-table"
          data={processedEducationData}
          onClickTr={(row: any) => {
            handleRowClick(row);
          }}
          hasDeleteIcon
        />
      )}
    </>
  );
};
export default EducationForm;
