/* eslint-disable no-unused-vars */
import React, { HTMLAttributes } from "react";

export interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode | string;
  onClick?: (e?: any) => void;
  className?: string;
  disabled?: boolean;
  label?: string;
  id?: string;
}

/**
 * teal UI component for user interaction
 */
const Icon: React.FC<IconProps> = ({
  children,
  onClick,
  id,
  className,
  disabled,
  label,
  ...props
}) => {
  const customOnClick = (e: any) => {
    if (!disabled) {
      if (onClick) {
        onClick(e);
      }
    }
  };
  return (
    <span
      data-testid="icon-general"
      id={`icon-general${id && `-${id}`}`}
      className={`${className} icon-label`}
      onClick={customOnClick}
      aria-hidden
      {...props}
    >
      {children}
      {label && <span className="text-sm text-gray-500">{label}</span>}
    </span>
  );
};

export default Icon;
