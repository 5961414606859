import { LANGUAGES } from "../pages/auth/user-profile/professional/ProfessionalForm";

const URL_API = "https://backend-6j1o.onrender.com";
// const URL_API = "http://localhost:3000";
export { URL_API };
export const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};
export function formatCurrency(
  amount: number | string,
  withoutSign: boolean = false,
  currency: string = "₡"
): string | boolean {
  console.log("🚀 ~ currency:", currency);

  // Convierte el tipo de dato a string para manipulación de texto
  let amountStr = String(amount);
  amountStr = amountStr.replace(/,/g, "");
  // Intenta convertir el string a número
  const parsedAmount = parseFloat(amountStr);

  // Verifica si el valor es NaN después de la conversión
  if (isNaN(parsedAmount)) {
    return false;
  }

  // Separa la parte entera y decimal con precisión de dos decimales
  let [integerPart, decimalPart = ""] = parsedAmount.toFixed(2).split(".");

  // Añade comas como separador de miles
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Retorna la cadena formateada
  return `${integerPart}.${decimalPart} ${withoutSign ? "" : currency}`;
}
type LanguageKeys = "en" | "es";

export function convertLanguage(
  language: string,
  currentLanguage: LanguageKeys
): string | undefined {
  const result = LANGUAGES.find((l) => language === l.id);
  return result?.[currentLanguage];
}
export const formatDateToCostaRica = (dateString: string): string => {
  const date = new Date(dateString);

  // Configuración para Costa Rica
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Formato de 24 horas
  };

  const formatter = new Intl.DateTimeFormat("es-CR", options);
  return formatter.format(date);
};
