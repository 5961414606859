let Tracker = null;

if (typeof window !== "undefined") {
  Tracker = require("@openreplay/tracker").default;
}

let trackerInstance: any = null;

export const getTrackerInstance = () => {
  if (typeof window !== "undefined" && Tracker && !trackerInstance) {
    trackerInstance = new Tracker({
      projectKey: "pqgceANCpCL552NKKUsG",
      __DISABLE_SECURE_MODE: true,
      onStart: ({ sessionID }: any) =>
        console.info("OpenReplay tracker started with session: ", sessionID),
    });
  }
  return trackerInstance;
};
