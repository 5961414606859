// ThTable.tsx
import React from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import Icon from "../Icon";
import { useTranslation } from "gatsby-plugin-react-i18next";

interface Props {
  setSortBy: (payload: string) => void;
  sortBy: string[] | undefined;
  id?: string;
  title: string;
  className?: string;
}

const ThTable = ({ sortBy, setSortBy, id, title, className }: Props) => {
  const { t } = useTranslation();
  return (
    <th
      onClick={() => setSortBy(title)}
      id={id}
      className={`hover:cursor-pointer ${className}`}
    >
      <div className="flex w-auto h-14 items-center border-b border-gray-200 bg-gray-50 text-left text-sm">
        <span className="text-sm w-full text-center font-semibold uppercase text-[#6B7280]">
          {t(title)}
        </span>
        {title && sortBy?.includes(title) && (
          <Icon onClick={setSortBy}>
            {sortBy?.includes("ASC") ? (
              <HiChevronDown
                data-testid="btn-down"
                size={20}
                className="text-gray-500"
              />
            ) : (
              <HiChevronUp
                data-testid="btn-up"
                size={20}
                className="text-gray-500"
              />
            )}
          </Icon>
        )}
      </div>
    </th>
  );
};
ThTable.defaultProps = {
  className: "",
  id: null,
};

export default ThTable;
