import { Listbox, Transition } from "@headlessui/react";
import React, { ChangeEvent } from "react";
import { Fragment } from "react";
import { HiCheck, HiChevronDown, HiX } from "react-icons/hi";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Store } from "react-notifications-component";
import toast from "react-hot-toast";

interface SelectProps<T> {
  items: { id: T; key: string; en: string; es: string }[];
  value: T | T[] | null;
  onChange: (value: T | T[] | null) => void;
  disabled?: boolean;
  multiple?: boolean;
  placeholder: string;
  className?: string;
  maxLength?: number;
  isPro?: boolean;
}

const Select = <T extends string | number>(
  {
    items,
    value,
    onChange,
    disabled,
    multiple,
    placeholder,
    className = "",
    maxLength,
    isPro = false,
  }: SelectProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const { t, i18n } = useTranslation();

  const getCurrentTranslation = (item: {
    key: string;
    en: string;
    es: string;
  }) => {
    if (i18n.language === "en") {
      return item.en;
    } else if (i18n.language === "es") {
      return item.es;
    } else {
      return "";
    }
  };

  const handleOptionSelect = (selected: T | T[] | null) => {
    // Comprobar si selected es null
    if (selected === null) {
      return; // No hacer nada
    }

    if (multiple) {
      if (Array.isArray(selected)) {
        // Comprobar si el número de elementos seleccionados no excede maxLength
        if (maxLength === undefined || selected.length <= maxLength) {
          onChange(selected.length > 0 ? selected : []);
        } else {
          !isPro &&
            toast.error(
              "Actualice al plan PRO para poder seleccionar más de una opción"
            );
        }
      } else {
        if (Array.isArray(value)) {
          if (value.includes(selected)) {
            const updatedValue = value.filter(
              (selectedId) => selectedId !== selected
            );
            onChange(updatedValue.length > 0 ? updatedValue : []);
          } else {
            // Comprobar si agregar el nuevo elemento excede maxLength
            if (value.length < maxLength) {
              onChange([...value, selected]);
            }
          }
        } else {
          onChange([selected]);
        }
      }
    } else {
      onChange(selected);
    }
  };
  const getDisplayValue = () => {
    if (multiple && Array.isArray(value)) {
      return value
        .map((v) => items.find((item) => item.id === v))
        .filter(Boolean)
        .map((item) => (item ? getCurrentTranslation(item) : ""))
        .join(", ");
    } else if (!multiple && value !== null) {
      const item = items.find((item) => item.id === value);
      return item ? getCurrentTranslation(item) : "";
    }
    return "";
  };

  return (
    <div className={`w-full ${className}`} ref={ref}>
      <label className="font-medium text-sm text-gray-700">{placeholder}</label>
      <Listbox
        value={value}
        onChange={handleOptionSelect}
        disabled={disabled}
        multiple={multiple}
      >
        <div className="relative mt-1">
          <Listbox.Button
            className={`h-8 relative text-start pl-2 border-gray-500 border  w-full rounded-lg ${
              disabled ? "bg-gray-200" : ""
            }`}
          >
            <span className="block truncate">
              {getDisplayValue() || placeholder}
            </span>

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <HiChevronDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-100 divide-y text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {items.map((item) => (
                <Listbox.Option
                  key={item.id.toString()}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-teal-100 text-teal-900" : "text-gray-900"
                    }`
                  }
                  value={item.id}
                  onClick={() => handleOptionSelect(item.id)}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {getCurrentTranslation(item)}
                      </span>
                      {selected && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-teal-600">
                          <HiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>

      {/* Selected items badges */}
      <div className="flex flex-wrap gap-2 mt-2">
        {Array.isArray(value) &&
          value.map((val, idx) => {
            const item = items.find((item) => item.id === val);
            if (item) {
              return (
                <div
                  key={idx}
                  className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-sm font-medium bg-teal-100 text-teal-800"
                >
                  <span>{getCurrentTranslation(item)}</span>
                  <button
                    type="button"
                    className="inline-flex text-teal-500 focus:outline-none focus:ring-2 focus:ring-offset-2 mx-2"
                    onClick={() => {
                      // Aquí es donde se maneja la eliminación del elemento
                      if (Array.isArray(value)) {
                        onChange(
                          value.filter((selectedId) => selectedId !== val)
                        );
                      }
                    }}
                  >
                    <span className="sr-only">Remove {item.key}</span>
                    <HiX className="h-4 w-4" />
                  </button>
                </div>
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};

export default React.forwardRef(Select) as <T extends string | number>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => JSX.Element;
